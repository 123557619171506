import React, { useState } from "react";
function RegalleVillageClinic() {
  return (
    <React.Fragment>
      <div className="row align-items-center mb-2">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <img
            src="../../assets/img/healthcaremobilemedicalunit/Poor connectivity.png"
            className="nutri-img img-thumbnail rounded w-100"
          />
        </div>
        <div className="col-lg-6">
          <p>
            To increase the health awareness & to provide quality healthcare
            services free of cost to the last mile, Initiative of mobile medical
            unit taken up in January 2021. <br /><br />
            MMU covers tribal population of 2300 members in 18 villages. Mobile
            medical unit visits habitations on scheduled basis and provides
            diagnostics & curative services for the communicable and
            non-communicable diseases. <br /><br />
            Through regular visits to the habitations, there has been gradual
            development of trust & bond between the community members and our
            team which is crucial for sustainability.
          </p>
        </div>
      </div>
      <div className="row align-items-center mb-2">
        <div className="col-lg-6 mb-4 mb-lg-0">
          <img
            src="../../assets/img/healthcaremobilemedicalunit/Poor connectivity2.png"
            className="nutri-img img-thumbnail rounded w-100"
          />
        </div>
        <div className="col-lg-6 mb-4 mb-lg-0">
          <img
            src="../../assets/img/healthcaremobilemedicalunit/Mobile Medical unit.png"
            className="nutri-img img-thumbnail rounded w-100"
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegalleVillageClinic;
